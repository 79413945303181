import React, { useState, useEffect } from "react";

import { styled } from "@mui/material/styles";

import Paper from "@mui/material/Paper";

import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

import api from "../../services/api";

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

function Cotacao({ click }) {
  const [loading, setLoading] = useState(true);
  const [precoOuro, setPrecoOuro] = useState(0);
  const [precoPrata, setPrecoPrata] = useState(0);
  const [precoData, setPrecoData] = useState("0");

  const apiPrecos = async () => {
    await api
      .get("/api/metal-precos/ultimo")
      .then((resp) => {
        // console.log(resp.data);
        if (resp.data.ouro) setPrecoOuro(resp.data.ouro);
        if (resp.data.prata) setPrecoPrata(resp.data.prata);
        if (resp.data.data) setPrecoData(resp.data.data);
        // setIsLoading(false);
        console.log(resp.data.data);
        setLoading(false);
      })
      .catch((err) => {
        // dsds
        // setIsLoading(false);
      });
  };

  const getValue = (value) => {
    if (click) {
      click(value);
    }
  };

  useEffect(() => {
    const pegarPrecos = async () => {
      await apiPrecos();
    };

    pegarPrecos();
  }, []);

  return (
    <>
      <Box noValidate sx={{ pl: 2, pr: 2, pb: 2, width: "100%" }}>
        <Item
          sx={{
            width: "100%",
            backgroundColor: "#d4af37",
            cursor: click ? "pointer" : "",
          }}
        >
          {loading ? (
            <CircularProgress size={15} sx={{ m: 1 }} />
          ) : (
            <>
              <strong>Preço do Ouro 1000</strong>
              <br />{" "}
              {new Intl.NumberFormat("pt-BR", {
                style: "currency",
                currency: "BRL",
              }).format(precoOuro)}
              /g
            </>
          )}
        </Item>
      </Box>
      <Stack
        direction="row"
        divider={<Divider orientation="vertical" flexItem />}
        spacing={2}
      >
        <Item
          sx={{
            width: "33%",
            backgroundColor: "#d4af37",
            cursor: click ? "pointer" : "",
          }}
          onClick={() => getValue(precoOuro * 0.75)}
        >
          {loading ? (
            <CircularProgress size={15} sx={{ m: 1 }} />
          ) : (
            <>
              <strong>AU 18k</strong>
              <br />{" "}
              {new Intl.NumberFormat("pt-BR", {
                style: "currency",
                currency: "BRL",
              }).format(precoOuro * 0.75)}
              /g
            </>
          )}
        </Item>
        <Item
          sx={{
            width: "33%",
            background: "#e3e3de",
          }}
          onClick={() => getValue(precoOuro * 0.84)}
        >
          {loading ? (
            <CircularProgress size={15} sx={{ m: 1 }} />
          ) : (
            <>
              <strong>BR/RS 18K</strong>
              <br />
              {new Intl.NumberFormat("pt-BR", {
                style: "currency",
                currency: "BRL",
              }).format(precoOuro * 0.84)}
              /g
            </>
          )}
        </Item>
        <Item sx={{ width: "33%" }} onClick={() => getValue(precoPrata * 2.6)}>
          {loading ? (
            <CircularProgress size={15} sx={{ m: 1 }} />
          ) : (
            <>
              <strong>AG 950</strong>
              <br />{" "}
              {new Intl.NumberFormat("pt-BR", {
                style: "currency",
                currency: "BRL",
              }).format(precoPrata * 2.6)}
              /g
            </>
          )}
        </Item>
      </Stack>
      <Stack sx={{ pt: 2 }}>
        <Typography fontSize={10}>
          <strong>Ultima Consulta: </strong>
          {new Date(precoData).toLocaleDateString("pt-BR")} -{" "}
          {new Date(precoData).toLocaleTimeString("pt-BR")}
        </Typography>
      </Stack>
    </>
  );
}

export default Cotacao;
