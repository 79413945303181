import React from "react";

import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";

import defaultConfig from "../../config/default";

function Detalhe({ pedido }) {
  return (
    <>
      <Stack
        direction="row"
        spacing={2}
        sx={{ pt: 2, pb: 2, alignItems: "center", justifyContent: "center" }}
      >
        {pedido && pedido.imagens
          ? pedido.imagens.map((file, idx) => (
              <Avatar
                key={idx}
                alt={file.name}
                src={defaultConfig.baseApi + file.formats.thumbnail.url}
                sx={{ width: 90, height: 90 }}
                variant="rounded"
              />
            ))
          : "Sem imagens"}
      </Stack>
      <List
        dense
        sx={{
          width: "100%",
          bgcolor: "background.paper",
        }}
        component="nav"
        aria-label="mailbox folders"
      >
        {pedido && pedido.status_pedido && (
          <ListItem>
            <ListItemText
              sx={{
                textAlign: "center",
                textTransform: "uppercase",
                borderRadius: 1,
                p: 1,
                backgroundColor:
                  pedido.status_pedido.id === 1
                    ? "#ff9800"
                    : pedido.status_pedido.id === 2
                    ? "#4caf50"
                    : pedido.status_pedido.id === 3
                    ? "#0288d1"
                    : "#C576F6",
              }}
            >
              <b>
                {pedido && pedido.status_pedido && pedido.status_pedido.nome}
              </b>
            </ListItemText>
          </ListItem>
        )}
        {pedido.entrega_chave ||
        pedido.preco_material ||
        pedido.preco_servico ||
        pedido.peso_falha ? (
          <>
            <ListItem sx={{ backgroundColor: "#ebecf0" }}>
              <ListItemText sx={{ textAlign: "left" }}>
                <b>Detalhes</b>
              </ListItemText>
            </ListItem>
            <Divider />
            {pedido.peso_falha && (
              <ListItem sx={{ backgroundColor: "#ebecf0" }}>
                <ListItemText
                  primary="Peso + Falha"
                  sx={{ textAlign: "left" }}
                />
                <ListItemText sx={{ textAlign: "right" }}>
                  <b>{pedido.peso + pedido.peso_falha}g</b>
                </ListItemText>
              </ListItem>
            )}
            {pedido.preco_material ? (
              <ListItem sx={{ backgroundColor: "#ebecf0" }}>
                <ListItemText
                  primary="Preço Material"
                  sx={{ textAlign: "left" }}
                />
                <ListItemText sx={{ textAlign: "right" }}>
                  <b>
                    {new Intl.NumberFormat("pt-BR", {
                      style: "currency",
                      currency: "BRL",
                    }).format(pedido.preco_material)}
                  </b>
                </ListItemText>
              </ListItem>
            ) : (
              pedido.peso &&
              pedido.peso_falha && (
                <ListItem sx={{ backgroundColor: "#ebecf0" }}>
                  <ListItemText
                    primary="Preço Material(Previsão)"
                    sx={{ textAlign: "left" }}
                  />
                  <ListItemText sx={{ textAlign: "right" }}>
                    <b>
                      {new Intl.NumberFormat("pt-BR", {
                        style: "currency",
                        currency: "BRL",
                      }).format((pedido.peso + pedido.peso_falha) * 20)}
                    </b>
                  </ListItemText>
                </ListItem>
              )
            )}
            {pedido.preco_servico && (
              <ListItem sx={{ backgroundColor: "#ebecf0" }}>
                <ListItemText
                  primary="Preço Serviço"
                  sx={{ textAlign: "left" }}
                />
                <ListItemText sx={{ textAlign: "right" }}>
                  <b>
                    {new Intl.NumberFormat("pt-BR", {
                      style: "currency",
                      currency: "BRL",
                    }).format(pedido.preco_servico)}
                  </b>
                </ListItemText>
              </ListItem>
            )}
            {/* {pedido.status_pagamento && (
              <ListItem sx={{ backgroundColor: "#ebecf0" }}>
                <ListItemText primary="Pagamento" sx={{ textAlign: "left" }} />
                <ListItemText sx={{ textAlign: "right" }}>
                  <b>{pedido.status_pagamento}</b>
                </ListItemText>
              </ListItem>
            )} */}
            {pedido.entrega_chave && (
              <ListItem sx={{ backgroundColor: "#ebecf0" }}>
                <ListItemText primary="Rastreio" sx={{ textAlign: "left" }} />
                <ListItemText sx={{ textAlign: "right" }}>
                  <b>{pedido.entrega_chave}</b>
                </ListItemText>
              </ListItem>
            )}
            <Divider />
          </>
        ) : (
          ""
        )}
        <ListItem>
          <ListItemText sx={{ textAlign: "left" }}>
            <b>Características</b>
          </ListItemText>
        </ListItem>
        <Divider />
        <ListItem>
          <ListItemText primary="Cliente" sx={{ textAlign: "left" }} />
          <ListItemText sx={{ textAlign: "right" }}>
            <b>{pedido && pedido.cliente ? pedido.cliente : "-"}</b>
          </ListItemText>
        </ListItem>
        <Divider />
        <ListItem>
          <ListItemText primary="Matéria Prima" sx={{ textAlign: "left" }} />
          <ListItemText sx={{ textAlign: "right" }}>
            <b>
              {pedido && pedido.materia_prima && pedido.materia_prima.nome
                ? pedido.materia_prima.nome
                : "-"}
            </b>
          </ListItemText>
        </ListItem>
        <Divider />
        <ListItem>
          <ListItemText primary="Tipo/Cor" sx={{ textAlign: "left" }} />
          <ListItemText sx={{ textAlign: "right" }}>
            <b>
              {pedido &&
              pedido.materia_prima_tipo &&
              pedido.materia_prima_tipo.nome
                ? pedido.materia_prima_tipo.nome
                : "-"}
            </b>
          </ListItemText>
        </ListItem>
        <Divider />
        <ListItem>
          <ListItemText primary="Modelo/Perfil" sx={{ textAlign: "left" }} />
          <ListItemText sx={{ textAlign: "right" }}>
            <b>
              {pedido && pedido.perfil && pedido.perfil.nome
                ? pedido.perfil.code
                : "-"}
            </b>
          </ListItemText>
        </ListItem>
        <Divider />
        <ListItem>
          <ListItemText primary="Unidade/Par" sx={{ textAlign: "left" }} />
          <ListItemText sx={{ textAlign: "right" }}>
            <b>
              {pedido && pedido.quantidade
                ? pedido.quantidade === 1
                  ? "Unidade"
                  : "Par"
                : "-"}
            </b>
          </ListItemText>
        </ListItem>
        <Divider />
        <ListItem>
          <ListItemText primary="Peso" sx={{ textAlign: "left" }} />
          <ListItemText sx={{ textAlign: "right" }}>
            <b>{pedido && pedido.peso ? pedido.peso + "g" : "-"}</b>
          </ListItemText>
        </ListItem>
        <Divider />
        <ListItem>
          <ListItemText primary="Largura" sx={{ textAlign: "left" }} />
          <ListItemText sx={{ textAlign: "right" }}>
            <b>{pedido && pedido.largura ? pedido.largura + "mm" : "-"}</b>
          </ListItemText>
        </ListItem>
        <Divider />
        <ListItem>
          <ListItemText primary="Espessura" sx={{ textAlign: "left" }} />
          <ListItemText sx={{ textAlign: "right" }}>
            <b>{pedido && pedido.espessura ? pedido.espessura + "mm" : "-"}</b>
          </ListItemText>
        </ListItem>
        <Divider />
        <ListItem>
          <ListItemText primary="Aliança 1" sx={{ textAlign: "left" }} />
          <ListItemText sx={{ textAlign: "right" }}>
            <b>{pedido && pedido.alianca_1 ? pedido.alianca_1 + " ⌀" : "-"}</b>
          </ListItemText>
        </ListItem>
        <Divider />
        {pedido && pedido.alianca_2 && (
          <>
            <ListItem>
              <ListItemText primary="Aliança 2" sx={{ textAlign: "left" }} />
              <ListItemText sx={{ textAlign: "right" }}>
                <b>
                  {pedido && pedido.alianca_2 ? pedido.alianca_2 + " ⌀" : "-"}
                </b>
              </ListItemText>
            </ListItem>
            <Divider />
          </>
        )}
        <ListItem>
          <ListItemText primary="Acab. Ext." sx={{ textAlign: "left" }} />
          <ListItemText sx={{ textAlign: "right" }}>
            <b>
              {pedido &&
              pedido.acabamento_externo &&
              pedido.acabamento_externo.nome
                ? pedido.acabamento_externo.nome
                : "-"}
            </b>
          </ListItemText>
        </ListItem>
        <Divider />
        <ListItem>
          <ListItemText primary="Acab. Fer." sx={{ textAlign: "left" }} />
          <ListItemText sx={{ textAlign: "right" }}>
            <b>
              {pedido &&
                pedido.acabamento_ferramentas &&
                pedido.acabamento_ferramentas
                  .map((acab) => acab.nome)
                  .join(", ")}
            </b>
          </ListItemText>
        </ListItem>
        <Divider />
        {pedido && pedido.servicos && (
          <>
            <ListItem>
              <ListItemText sx={{ textAlign: "left" }}>
                <b>Serviços</b>
              </ListItemText>
            </ListItem>
            <Divider />
          </>
        )}
        {pedido &&
          pedido.servicos &&
          pedido.servicos.map((serv, idx) => (
            <React.Fragment key={idx}>
              <ListItem key={idx}>
                <ListItemText
                  primary={
                    <b>{(serv && serv.servico && serv.servico.nome) || "-"}</b>
                  }
                  secondary={
                    <>
                      {serv.servico_tipo && serv.servico_tipo.nome ? (
                        <>
                          - {serv.servico_tipo.nome}
                          <br />
                        </>
                      ) : (
                        ""
                      )}
                      <b>Aliança 1 ({pedido.alianca_1}):</b> {serv.alianca_1}
                      <br />
                      {serv.alianca_2 && (
                        <>
                          <b>Aliança 2({pedido.alianca_2}):</b> {serv.alianca_2}
                        </>
                      )}
                      {serv.obs && <>{serv.obs}</>}
                    </>
                  }
                />
              </ListItem>
              <Divider key={idx + "-" + serv.servico} />
            </React.Fragment>
          ))}
        <ListItem>
          <ListItemText
            primary={<b>Observações Finais</b>}
            secondary={(pedido && pedido.observacao) || "-"}
          />
        </ListItem>
        <Divider />
        <ListItem>
          <ListItemText sx={{ textAlign: "left" }}>
            <b>Prazo Entrega</b>
          </ListItemText>
          <ListItemText sx={{ textAlign: "right" }}>
            <b>
              {pedido && pedido.data_entrega
                ? new Date(pedido.data_entrega).toLocaleDateString("pt-BR") +
                  " após às 16:00"
                : "a definir"}
            </b>
          </ListItemText>
        </ListItem>
        <Divider />
        <ListItem>
          <ListItemText sx={{ textAlign: "left" }}>
            <b>Entrega</b>
          </ListItemText>
          <ListItemText sx={{ textAlign: "right" }}>
            <b>
              {pedido && pedido.entrega && pedido.entrega.nome
                ? pedido.entrega.nome
                : "-"}
            </b>
          </ListItemText>
        </ListItem>
        <Divider />
        {pedido && pedido.endereco && (
          <>
            <ListItem>
              <ListItemText
                primary="Endereço"
                secondary={pedido.endereco.endereco || "-"}
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="Número"
                secondary={pedido.endereco.numero || "-"}
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="Complemento"
                secondary={pedido.endereco.complemento || "-"}
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="Bairro"
                secondary={pedido.endereco.bairro || "-"}
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="Cidade"
                secondary={pedido.endereco.cidade || "-"}
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="Estado"
                secondary={pedido.endereco.estado || "-"}
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="CEP"
                secondary={pedido.endereco.cep || "-"}
              />
            </ListItem>
            <Divider />
          </>
        )}
      </List>
    </>
  );
}

export default Detalhe;
