import { useState, useEffect } from "react";
// import * as qs from "qs";
// import { addDays, format } from "date-fns";

import Grid from "@mui/material/Grid";

import axios from "axios";
import Layout from "../../layouts/default";
// import api from "../../services/api";

import { Link } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import FormHelperText from "@mui/material/FormHelperText";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";

import Logo from "../../components/logo";

import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

const schema = Yup.object({
  preco_dolar: Yup.number().required("Preço do dolar é obrigatório"),
  // peso: Yup.string().required("Peso é obrigatório"),
});

export default function OrcamentoDiamante() {
  // const [cravacoes, setCravacoes] = useState([]);
  const [precoDolar, setPrecoDolar] = useState(0);
  const [valorVenda, setValorVenda] = useState(0);
  const [valorCusto, setValorCusto] = useState(0);
  const [valorUnitario, setValorUnitario] = useState(0);
  const [quantidadePedras, setQuantidadePedras] = useState(0);
  const [medidaPedras, setMedidaPedras] = useState(0);

  useEffect(() => {
    axios
      .get("https://economia.awesomeapi.com.br/json/last/USD-BRL")
      .then((response) => {
        // console.log(response.data.USDBRL.ask);
        // setPrecoDolar();
        setValue("preco_dolar", Number(response.data.USDBRL.ask).toFixed(2));
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const tabelaPedras = [
    {
      ct: 0.005,
      desc: "0,005ct - 1.0 mm",
      quali: [
        { valor: 330, nome: "I - K/L" },
        { valor: 415, nome: "Si/i - J/K" },
        { valor: 490, nome: "Vs/Si - I/J" },
        { valor: 580, nome: "Vs - H/l" },
        { valor: 680, nome: "Vvs/Vs - G/H" },
        { valor: 810, nome: "Vvs - F/G" },
      ],
    },
    {
      ct: 0.008,
      desc: "0,008ct - 1,2 mm",
      quali: [
        { valor: 330, nome: "I - K/L" },
        { valor: 415, nome: "Si/i - J/K" },
        { valor: 490, nome: "Vs/Si - I/J" },
        { valor: 580, nome: "Vs - H/l" },
        { valor: 680, nome: "Vvs/Vs - G/H" },
        { valor: 810, nome: "Vvs - F/G" },
      ],
    },
    {
      ct: 0.01,
      desc: "0,01ct - 1,3 mm",
      quali: [
        { valor: 330, nome: "I - K/L" },
        { valor: 415, nome: "Si/i - J/K" },
        { valor: 490, nome: "Vs/Si - I/J" },
        { valor: 580, nome: "Vs - H/l" },
        { valor: 680, nome: "Vvs/Vs - G/H" },
        { valor: 810, nome: "Vvs - F/G" },
      ],
    },
    {
      ct: 0.015,
      desc: "0,015ct - 1,5 mm",
      quali: [
        { valor: 330, nome: "I - K/L" },
        { valor: 415, nome: "Si/i - J/K" },
        { valor: 490, nome: "Vs/Si - I/J" },
        { valor: 580, nome: "Vs - H/l" },
        { valor: 680, nome: "Vvs/Vs - G/H" },
        { valor: 810, nome: "Vvs - F/G" },
      ],
    },
    {
      ct: 0.02,
      desc: "0,02ct - 1,7 mm",
      quali: [
        { valor: 330, nome: "I - K/L" },
        { valor: 415, nome: "Si/i - J/K" },
        { valor: 490, nome: "Vs/Si - I/J" },
        { valor: 580, nome: "Vs - H/l" },
        { valor: 680, nome: "Vvs/Vs - G/H" },
        { valor: 810, nome: "Vvs - F/G" },
      ],
    },
    {
      ct: 0.03,
      desc: "0,03ct - 2,0 mm",
      quali: [
        { valor: 490, nome: "Si - H/I" },
        { valor: 600, nome: "Vs - G/H" },
        { valor: 830, nome: "Vvs - E/F" },
      ],
    },
    {
      ct: 0.04,
      desc: "0,04ct - 2,2 mm",
      quali: [
        { valor: 490, nome: "Si - H/I" },
        { valor: 600, nome: "Vs - G/H" },
        { valor: 830, nome: "Vvs - E/F" },
      ],
    },
    {
      ct: 0.05,
      desc: "0,05ct - 2,4 mm",
      quali: [
        { valor: 490, nome: "Si - H/I" },
        { valor: 600, nome: "Vs - G/H" },
        { valor: 830, nome: "Vvs - E/F" },
      ],
    },
    {
      ct: 0.06,
      desc: "0,06ct - 2,5 mm",
      quali: [
        { valor: 490, nome: "Si - H/I" },
        { valor: 600, nome: "Vs - G/H" },
        { valor: 830, nome: "Vvs - E/F" },
      ],
    },
    {
      ct: 0.07,
      desc: "0,07ct - 2,7 mm",
      quali: [
        { valor: 490, nome: "Si - H/I" },
        { valor: 600, nome: "Vs - G/H" },
        { valor: 830, nome: "Vvs - E/F" },
      ],
    },
    {
      ct: 0.1,
      desc: "0,10ct - 3,0 mm",
      quali: [
        { valor: 710, nome: "Vs/Si - J/K" },
        { valor: 970, nome: "Vvs/Vs - F/G" },
      ],
    },
    {
      ct: 0.15,
      desc: "0,15ct - 3,4 mm",
      quali: [
        { valor: 810, nome: "Vs/Si - J/K" },
        { valor: 1000, nome: "Vvs/Vs - F/G" },
      ],
    },
    {
      ct: 0.2,
      desc: "0,20ct - 3,8 mm",
      quali: [
        { valor: 1100, nome: "Vs/Si - J/K" },
        { valor: 1390, nome: "Vvs/Vs - F/G" },
      ],
    },
    {
      ct: 0.25,
      desc: "0,25ct - 4,0 mm",
      quali: [
        { valor: 1220, nome: "Vs/Si - J/K" },
        { valor: 1500, nome: "Vvs/Vs - F/G" },
      ],
    },
    {
      ct: 0.3,
      desc: "0,30ct - 4,2 mm",
      quali: [
        { valor: 1350, nome: "Vs/Si - J/K" },
        { valor: 1760, nome: "Vvs/Vs - F/G" },
      ],
    },
    {
      ct: 0.4,
      desc: "0,40ct - 4,8 mm",
      quali: [
        { valor: 1550, nome: "Vs/Si - J/K" },
        { valor: 2400, nome: "Vvs/Vs - F/G" },
      ],
    },
    {
      ct: 0.5,
      desc: "0,50ct - 5,0 mm",
      quali: [
        { valor: 2200, nome: "Vs/Si - J/K" },
        { valor: 3000, nome: "Vvs/Vs - F/G" },
      ],
    },
  ];

  const {
    register,
    handleSubmit,
    control,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const watchMedidaPedras = watch("medida_pedras");
  // const watchTitulo = watch("titulo");
  // const watchQtd = watch("quantidade");

  const handleOnSubmit = (data) => {
    const MarkUp = 1.3;
    setPrecoDolar(data.preco_dolar.toFixed(2));

    console.log(data);

    setQuantidadePedras(data.quantidade_pedras);
    setMedidaPedras(data.medida_pedras);

    const lucro = data.lucro ? Number(data.lucro) : 0;

    const custo_pedra =
      data.qualidade_pedras * data.medida_pedras * data.preco_dolar * MarkUp;

    const preco_pedra = (custo_pedra * 100) / (100 - lucro);

    setValorCusto((custo_pedra * data.quantidade_pedras).toFixed(2));
    setValorUnitario(preco_pedra.toFixed(2));
    setValorVenda((preco_pedra * data.quantidade_pedras).toFixed(2));
  };

  useEffect(() => {
    //Dados Iniciais
    setValue("quantidade_pedras", 1);
  }, [setValue]);

  return (
    <Layout>
      <AppBar position="static" color="primary" enableColorOnDark />
      <Grid sx={{ pt: 4, pb: 4 }}>
        <Logo />
      </Grid>
      {/* <Cotacao click={(value) => changePrice(value)} /> */}
      <Box
        component="form"
        onSubmit={handleSubmit(handleOnSubmit)}
        noValidate
        sx={{ mt: 1, maxWidth: 300, width: "100%" }}
      >
        <Typography sx={{ mt: 1, mb: 0, fontWeight: "bold" }}>
          Orçamento Diamante
        </Typography>
        <Typography sx={{ mt: 0, mb: 3, fontSize: 14 }}>
          Preencha os dados para ter um orçamento!
        </Typography>
        <FormControl fullWidth sx={{ mb: 3 }} variant="outlined">
          <TextField
            type="number"
            label="Preço Dolar"
            fullWidth
            id="preco_dolar"
            name="preco_dolar"
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">R$</InputAdornment>
              ),
            }}
            error={errors.preco_dolar ? true : false}
            {...register("preco_dolar")}
          />
        </FormControl>
        <FormControl
          fullWidth
          sx={{ mb: 3 }}
          error={errors.preco_dolar ? true : false}
        >
          <InputLabel id="medida_pedras_label">Medidas</InputLabel>
          <Controller
            name="medida_pedras"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <Select
                {...field}
                labelId="medida_pedras_label"
                id="medida_pedras"
                label="Medidas"
                {...register("medida_pedras")}
              >
                {tabelaPedras.map((item, id) => (
                  <MenuItem key={id} value={item.ct}>
                    {item.desc}
                  </MenuItem>
                ))}
              </Select>
            )}
          />

          {errors && errors.medida_pedras && errors.medida_pedras.message && (
            <FormHelperText>{errors.medida_pedras.message}</FormHelperText>
          )}
        </FormControl>
        <FormControl
          fullWidth
          sx={{ mb: 3 }}
          error={errors.qualidade_pedras ? true : false}
        >
          <InputLabel id="qualidade_pedras_label">Qualidade</InputLabel>
          <Controller
            name="qualidade_pedras"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <Select
                {...field}
                labelId="qualidade_pedras_label"
                id="qualidade_pedras"
                label="Qualidade"
                {...register("qualidade_pedras")}
              >
                {watchMedidaPedras &&
                  tabelaPedras
                    .find(({ ct }) => ct === watchMedidaPedras)
                    .quali.map((item, id) => (
                      <MenuItem key={id} value={item.valor}>
                        {item.nome}
                      </MenuItem>
                    ))}
              </Select>
            )}
          />

          {errors &&
            errors.qualidade_pedras &&
            errors.qualidade_pedras.message && (
              <FormHelperText>{errors.qualidade_pedras.message}</FormHelperText>
            )}
        </FormControl>
        <FormControl fullWidth sx={{ mb: 3 }} variant="outlined">
          <TextField
            type="number"
            label="Qtd Pedras"
            fullWidth
            id="quantidade_pedras"
            name="quantidade_pedras"
            InputLabelProps={{
              shrink: true,
            }}
            error={errors.quantidade_pedras ? true : false}
            {...register("quantidade_pedras")}
          />
        </FormControl>
        <FormControl fullWidth sx={{ mb: 3 }} variant="outlined">
          <TextField
            type="number"
            label="Lucro"
            fullWidth
            id="lucro"
            name="lucro"
            defaultValue={30}
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
              inputMode: "numeric",
            }}
            error={errors.lucro ? true : false}
            {...register("lucro")}
          />
        </FormControl>
        <FormControl fullWidth sx={{ mb: 3 }}>
          <Button variant="contained" size="medium" type="submit">
            Calcular
          </Button>
          <p />
          <Button component={Link} to="/" autoFocus>
            Voltar
          </Button>
        </FormControl>
      </Box>
      {valorVenda > 0 && (
        <Box>
          <strong>Custos</strong>
          <br />
          <strong>Preço Dolar:</strong> R$ {precoDolar ? precoDolar : ""}
          <br />
          <strong>Medida Pedra:</strong> {medidaPedras ? medidaPedras : ""}ct
          <br />
          <strong>Quantidade:</strong>{" "}
          {quantidadePedras ? quantidadePedras : ""}
          <br />
          <strong>Preço Unitario:</strong> R${" "}
          {valorUnitario ? valorUnitario : ""}
          <br />
          <strong>Preço Final de Venda:</strong> R${" "}
          {valorVenda ? valorVenda : ""}
          <br />
          ---------------------------
          <br />
          <strong>Preço de Custo:</strong> R$ {valorCusto ? valorCusto : ""}
          <br />
          <strong>Lucro:</strong> R${" "}
          {valorCusto && valorVenda ? (valorVenda - valorCusto).toFixed(2) : ""}
          <p>*o valor do frete não está incluso.</p>
        </Box>
      )}
    </Layout>
  );
}
