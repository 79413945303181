import { useState, useEffect } from "react";

import Grid from "@mui/material/Grid";

import Layout from "../../layouts/default";

import { Link } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import FormHelperText from "@mui/material/FormHelperText";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
// import InputAdornment from "@mui/material/InputAdornment";

import Logo from "../../components/logo";

import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

const schema = Yup.object({
  peso_seco: Yup.number().required("Você deve escolhar se é Par ou Unidade"),
  // peso: Yup.string().required("Peso é obrigatório"),
});

export default function Teor({ voltar = false }) {
  // const [cravacoes, setCravacoes] = useState([]);
  const [resultadoTeor, setResultadoTeor] = useState("");
  const [pesoSeco, setPesoSeco] = useState(0);
  const [tipoCalculo, setTipoCalculo] = useState("au1000");

  const {
    register,
    handleSubmit,
    control,
    // watch,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  // const watchMedidaPedras = watch("tipo_calculo");
  // const watchTitulo = watch("titulo");
  // const watchQtd = watch("quantidade");

  const handleOnSubmit = (data) => {
    const tipo_calculo = data.tipo_calculo;
    const peso_seco = data.peso_seco;
    const peso_molhado = data.peso_molhado;

    setPesoSeco(peso_seco);
    setTipoCalculo(tipo_calculo);

    if (tipo_calculo === "au1000") {
      const resultadoTeor = (peso_molhado / peso_seco) * 2307 - 2088;
      setResultadoTeor(resultadoTeor.toFixed(2));
    } else if (tipo_calculo === "au18k") {
      const resultadoTeor =
        ((((peso_seco / (peso_seco - peso_molhado) - 9.6) / 9.7) * 19.32) /
          (peso_seco / (peso_seco - peso_molhado))) *
        0.98;
      setResultadoTeor(resultadoTeor.toFixed(2) * 100);
    }
  };

  useEffect(() => {
    //Dados Iniciais
    setValue("peso_seco", "");
    setValue("peso_molhado", "");
  }, [setValue]);

  return (
    <Layout>
      <AppBar position="static" color="primary" enableColorOnDark />
      <Grid sx={{ pt: 4, pb: 4 }}>
        <Logo />
      </Grid>
      {/* <Cotacao click={(value) => changePrice(value)} /> */}
      <Box
        component="form"
        onSubmit={handleSubmit(handleOnSubmit)}
        noValidate
        sx={{ mt: 1, maxWidth: 300, width: "100%" }}
      >
        <Typography sx={{ mt: 1, mb: 0, fontWeight: "bold" }}>
          Calcular Teor de Ouro
        </Typography>
        <Typography sx={{ mt: 0, mb: 3, fontSize: 14 }}>
          Necessário o uso da balança!
        </Typography>
        <FormControl
          fullWidth
          sx={{ mb: 3 }}
          error={errors.peso_seco ? true : false}
        >
          <InputLabel id="tipo_calculo_label">Tipo de Fórmula</InputLabel>
          <Controller
            name="tipo_calculo"
            control={control}
            defaultValue="au1000"
            render={({ field }) => (
              <Select
                {...field}
                labelId="tipo_calculo_label"
                id="tipo_calculo"
                label="Tipo de Calculo"
                {...register("tipo_calculo")}
              >
                <MenuItem value="au1000">Ouro 1000</MenuItem>
                <MenuItem value="au18k">Ouro 18K</MenuItem>
              </Select>
            )}
          />

          {errors && errors.tipo_calculo && errors.tipo_calculo.message && (
            <FormHelperText>{errors.tipo_calculo.message}</FormHelperText>
          )}
        </FormControl>
        <FormControl fullWidth sx={{ mb: 3 }} variant="outlined">
          <TextField
            type="number"
            label="Peso Seco (g)"
            fullWidth
            id="peso_seco"
            name="peso_seco"
            InputLabelProps={{
              shrink: true,
            }}
            error={errors.peso_seco ? true : false}
            {...register("peso_seco")}
          />
        </FormControl>

        <FormControl fullWidth sx={{ mb: 3 }} variant="outlined">
          <TextField
            type="number"
            label="Peso Molhado (g)"
            fullWidth
            id="peso_molhado"
            name="peso_molhado"
            InputLabelProps={{
              shrink: true,
            }}
            error={errors.peso_molhado ? true : false}
            {...register("peso_molhado")}
          />
        </FormControl>
        <FormControl fullWidth sx={{ mb: 3 }}>
          <Button variant="contained" size="medium" type="submit">
            Calcular
          </Button>
          {voltar && (
            <Button component={Link} to="/" autoFocus>
              Voltar
            </Button>
          )}
        </FormControl>
      </Box>

      {resultadoTeor && resultadoTeor > 0 && resultadoTeor <= 100 && (
        <>
          <Box>
            <strong>Resultado</strong>
            <br />
            <strong>Teor</strong>
            <br />
            <Typography
              color="green"
              variant="h3"
              sx={{ mt: 1, mb: 0, fontWeight: "bold" }}
            >
              {resultadoTeor ? resultadoTeor : ""}%
            </Typography>
            <br />
            <strong>Peso Total</strong> {pesoSeco}g
            <br />
            <strong>Peso de mil</strong>{" "}
            {(pesoSeco * (resultadoTeor / 100)).toFixed(2)}g
          </Box>
        </>
      )}

      {resultadoTeor && (resultadoTeor <= 0 || resultadoTeor > 100) && (
        <>
          <Box>
            <Typography
              color="red"
              variant="h3"
              sx={{ mt: 1, mb: 0, fontWeight: "bold" }}
            >
              Resultado Incorreto
            </Typography>
          </Box>
        </>
      )}
    </Layout>
  );
}
