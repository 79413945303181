import React, { useEffect, useState } from "react";

import Layout from "../../layouts/full";

// import { Link } from "react-router-dom";

export default function Criador() {
  // const [cravacoes, setCravacoes] = useState([]);
  useEffect(() => {
    const scriptReact = document.createElement("script");
    scriptReact.src =
      "https://unpkg.com/react@18.2.0/umd/react.production.min.js";
    scriptReact.onload = () => console.log("React carregado");
    document.body.appendChild(scriptReact);

    // Criação da tag script para ReactDOM
    const scriptReactDOM = document.createElement("script");
    scriptReactDOM.src =
      "https://unpkg.com/react-dom@18.2.0/umd/react-dom.production.min.js";
    scriptReactDOM.onload = () => console.log("ReactDOM carregado");
    document.body.appendChild(scriptReactDOM);

    const link = document.createElement("link");
    link.href = "https://wild.criador.pro/static/css/main.css?ver=0.1";
    link.rel = "stylesheet";
    link.type = "text/css";
    document.head.appendChild(link);

    const script = document.createElement("script");
    script.src = "https://wild.criador.pro/static/js/main.js?ver=0.1";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(scriptReact);
      document.body.removeChild(scriptReactDOM);
      document.head.removeChild(link);
      document.body.removeChild(script);
    };
  }, []);

  return (
    <Layout sx={{ width: 1 }}>
      <noscript>
        Você precisa habilitar o JavaScript para ver esta página.
      </noscript>
      <div
        id="criador-aliancas"
        data-embeded="true"
        data-default-settings='{"withHeader":false,"storeKey":"akiva","isAdmin":true}'
        style={{ width: "100%" }}
      >
        Aguarde o carregamento...
      </div>
    </Layout>
  );
}
