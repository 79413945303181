import React, { useState, useEffect } from "react";
import * as qs from "qs";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import AppBar from "@mui/material/AppBar";

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import CircularProgress from "@mui/material/CircularProgress";

import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import { Link } from "react-router-dom";

import api from "../../../services/api";

import Layout from "../../../layouts/default";

const Title = ({ children, color }) => {
  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        backgroundColor: color,
      }}
    >
      <Typography
        sx={{ flex: "1 1 100%" }}
        variant="span"
        id="tableTitle"
        component="div"
      >
        {children}
      </Typography>
    </Toolbar>
  );
};

export default function Lista() {
  // const [pedido, setPedido] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [encaminhados, setEncaminhados] = useState([]);
  const [emProducao, setEmProducao] = useState([]);
  const [entregas, setEntregas] = useState([]);
  // let params = useParams();

  const getPedidos = async (id) => {
    setIsLoading(true);
    const query = qs.stringify(
      {
        populate: ["perfil", "status_pedido", "materia_prima"],
        fields: ["id", "codigo", "largura", "peso", "alianca_1", "alianca_2"],
        filters: {
          $and: [
            {
              status_pedido: {
                $not: 4,
              },
            },
          ],
        },
        pagination: {
          page: 1,
          pageSize: 200,
        },
      },
      {
        encodeValuesOnly: true,
      }
    );

    await api
      .get(`/api/pedidos/?${query}`)
      .then(function (response) {
        // handle success
        const resp = response.data.data;
        console.log(resp);
        // setPedido(response.data);

        const encaminhados = resp.filter(
          (item) => item.attributes.status_pedido.data.id === 1
        );
        setEncaminhados(encaminhados);

        const emProducao = resp.filter(
          (item) => item.attributes.status_pedido.data.id === 2
        );
        setEmProducao(emProducao);

        const entregas = resp.filter(
          (item) => item.attributes.status_pedido.data.id === 3
        );
        setEntregas(entregas);
      })
      .catch(function (error) {
        // handle error
        // console.log(error);
        // setLoadingCep(false);
      })
      .then(function () {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getPedidos();
  }, []);

  return (
    <Layout>
      <Box sx={{ width: "100%" }}>
        <Paper sx={{ width: "100%", mb: 2 }}>
          <AppBar position="static" color="primary" enableColorOnDark>
            <Toolbar>
              <IconButton
                size="large"
                edge="start"
                color="inherit"
                aria-label="menu"
                sx={{ mr: 2 }}
                component={Link}
                to="/"
              >
                <ArrowBackIcon />
              </IconButton>
              <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                Seus Pedidos
              </Typography>
              <Button
                component={Link}
                to="/pedido/finalizados"
                variant="contained"
                size="small"
              >
                Arquivo
              </Button>
            </Toolbar>
          </AppBar>
          <Dialog open={isLoading} sx={{ p: 3 }}>
            <DialogContent>
              <CircularProgress />
            </DialogContent>
          </Dialog>
          <Title color="#ff9800">
            <strong>Encaminhados</strong>
          </Title>
          <TableContainer component={Paper}>
            <Table sx={{}} size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Pedido</TableCell>
                  <TableCell align="center">Modelo</TableCell>
                  <TableCell align="center">Largura</TableCell>
                  <TableCell align="center">Peso</TableCell>
                  <TableCell align="center" sx={{ minWidth: 100 }}>
                    Aliança 1
                  </TableCell>
                  <TableCell align="center" sx={{ minWidth: 100 }}>
                    Aliança 2
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {encaminhados && encaminhados.length > 0 ? (
                  encaminhados.map((pedido, idx) => (
                    <TableRow
                      key={"en" + pedido.id}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell component="th" scope="row">
                        <Link to={`/pedido/${pedido.id}`}>
                          {pedido.attributes.codigo ||
                            (pedido.attributes.materia_prima &&
                              pedido.attributes.materia_prima.data &&
                              pedido.attributes.materia_prima.data.attributes &&
                              pedido.attributes.materia_prima.data.attributes
                                .codigo) + pedido.id}
                        </Link>
                      </TableCell>
                      <TableCell align="center">
                        {(pedido.attributes &&
                          pedido.attributes.perfil &&
                          pedido.attributes.perfil.data &&
                          pedido.attributes.perfil.data.attributes &&
                          pedido.attributes.perfil.data.attributes.code) ||
                          "-"}
                      </TableCell>
                      <TableCell align="center">
                        {pedido.attributes.largura
                          ? pedido.attributes.largura + "mm"
                          : "-"}
                      </TableCell>
                      <TableCell align="center">
                        {pedido.attributes.peso
                          ? pedido.attributes.peso + "g"
                          : "-"}
                      </TableCell>
                      <TableCell align="center">
                        {pedido.attributes.alianca_1 || "-"}
                      </TableCell>
                      <TableCell align="center">
                        {pedido.attributes.alianca_2 || "-"}
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableCell sx={{ pt: 2, pb: 2 }} colSpan={5} align="center">
                    Não há pedido encaminhados.{" "}
                    <Link to="/pedido/novo">Crie um novo</Link>
                  </TableCell>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          {emProducao && emProducao.length > 0 && (
            <>
              <Title color="#4caf50">Em Produção</Title>
              <TableContainer component={Paper}>
                <Table sx={{}} size="small" aria-label="a dense table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Pedido</TableCell>
                      <TableCell align="center">Modelo</TableCell>
                      <TableCell align="center">Largura</TableCell>
                      <TableCell align="center">Peso</TableCell>
                      <TableCell align="center" sx={{ minWidth: 100 }}>
                        Aliança 1
                      </TableCell>
                      <TableCell align="center" sx={{ minWidth: 100 }}>
                        Aliança 2
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {emProducao.map((pedido, idx) => (
                      <TableRow
                        key={"en" + pedido.id}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          <Link to={`/pedido/${pedido.id}`}>
                            {pedido.attributes.codigo ||
                              (pedido.attributes.materia_prima &&
                                pedido.attributes.materia_prima.data &&
                                pedido.attributes.materia_prima.data
                                  .attributes &&
                                pedido.attributes.materia_prima.data.attributes
                                  .codigo) + pedido.id}
                          </Link>
                        </TableCell>
                        <TableCell align="center">
                          {(pedido.attributes &&
                            pedido.attributes.perfil &&
                            pedido.attributes.perfil.data &&
                            pedido.attributes.perfil.data.attributes &&
                            pedido.attributes.perfil.data.attributes.code) ||
                            "-"}
                        </TableCell>
                        <TableCell align="center">
                          {pedido.attributes.largura
                            ? pedido.attributes.largura + "mm"
                            : "-"}
                        </TableCell>
                        <TableCell align="center">
                          {pedido.attributes.peso
                            ? pedido.attributes.peso + "g"
                            : "-"}
                        </TableCell>
                        <TableCell align="center">
                          {pedido.attributes.alianca_1 || "-"}
                        </TableCell>
                        <TableCell align="center">
                          {pedido.attributes.alianca_2 || "-"}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          )}
          {entregas && entregas.length > 0 && (
            <>
              <Title color="#0288d1">Prontos para entrega</Title>
              <TableContainer component={Paper}>
                <Table sx={{}} size="small" aria-label="a dense table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Pedido</TableCell>
                      <TableCell align="center">Modelo</TableCell>
                      <TableCell align="center">Largura</TableCell>
                      <TableCell align="center">Peso</TableCell>
                      <TableCell align="center" sx={{ minWidth: 100 }}>
                        Aliança 1
                      </TableCell>
                      <TableCell align="center" sx={{ minWidth: 100 }}>
                        Aliança 2
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {entregas.map((pedido, idx) => (
                      <TableRow
                        key={"en" + pedido.id}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          <Link to={`/pedido/${pedido.id}`}>
                            {pedido.attributes.codigo ||
                              (pedido.attributes.materia_prima &&
                                pedido.attributes.materia_prima.data &&
                                pedido.attributes.materia_prima.data
                                  .attributes &&
                                pedido.attributes.materia_prima.data.attributes
                                  .codigo) + pedido.id}
                          </Link>
                        </TableCell>
                        <TableCell align="center">
                          {(pedido.attributes &&
                            pedido.attributes.perfil &&
                            pedido.attributes.perfil.data &&
                            pedido.attributes.perfil.data.attributes &&
                            pedido.attributes.perfil.data.attributes.code) ||
                            "-"}
                        </TableCell>
                        <TableCell align="center">
                          {pedido.attributes.largura
                            ? pedido.attributes.largura + "mm"
                            : "-"}
                        </TableCell>
                        <TableCell align="center">
                          {pedido.attributes.peso
                            ? pedido.attributes.peso + "g"
                            : "-"}
                        </TableCell>
                        <TableCell align="center">
                          {pedido.attributes.alianca_1 || "-"}
                        </TableCell>
                        <TableCell align="center">
                          {pedido.attributes.alianca_2 || "-"}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          )}
        </Paper>
      </Box>
    </Layout>
  );
}
