import React, { useEffect, useState } from "react";
import * as qs from "qs";

// import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";

import { AiOutlinePrinter, AiOutlineInfoCircle } from "react-icons/ai";
import { GrFormNextLink } from "react-icons/gr";

import api from "../../../services/api";

import { Container, Menu, TList, TCard } from "./styles";

import Detalhe from "../../../components/pedido/detalhe";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const Producao = () => {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [setores, setSetores] = useState([]);
  const [pedidos, setPedidos] = useState([]);
  const [pedido, setPedido] = useState();

  const handleOpenDetails = async (id) => {
    getPedido(id);
    // setOpen(!open);
  };

  const getPedido = async (id) => {
    setLoading(true);
    const query = qs.stringify(
      {
        populate: [
          "imagens",
          "perfil",
          "status_pedido",
          "cliente",
          "materia_prima",
          "materia_prima_tipo",
          "acabamento_externo",
          "acabamento_ferramentas",
          "servicos",
          "servicos.servico",
          "servicos.servico_tipo",
          "entrega",
          "endereco",
        ],
        fields: [
          "id",
          "codigo",
          "largura",
          "peso",
          "alianca_1",
          "alianca_2",
          "quantidade",
          "espessura",
          "observacao",
          "entrega_chave",
          "preco_material",
          "preco_servico",
          "peso_falha",
          "data_entrega",
        ],
      },
      {
        encodeValuesOnly: true,
      }
    );

    await api
      .get(`/api/pedidos/${id}?${query}`)
      .then(function (response) {
        // handle success

        // console.log(response.data);
        setPedido(response.data);
      })
      .catch(function (error) {
        // handle error
        // console.log(error);
        // setLoadingCep(false);
      })
      .then(function () {
        // always executed
        setOpen(true);
        setLoading(false);
      });
  };

  const apiSetores = async () => {
    setLoading(true);
    await api
      .get("/api/producao-setores?sort=ordem")
      .then((resp) => {
        setSetores(resp.data.data);
        // setIsLoading(false);
        setLoading(false);
      })
      .catch((err) => {
        // dsds
        // setIsLoading(false);
        setLoading(false);
      });
  };

  const apiPedidos = async () => {
    setLoading(true);
    await api
      .get("/api/pedidos?type=total&order=createdAt&populate=*")
      .then((resp) => {
        setPedidos(resp.data.data);
        // setIsLoading(false);
        setLoading(false);
      })
      .catch((err) => {
        // dsds
        // setIsLoading(false);
        setLoading(false);
      });
  };

  const enviarProximoSetor = async (id) => {
    setLoading(true);
    await api
      .get(`/api/pedidos/${id}/proximo`)
      .then((resp) => {
        console.log(resp.data);
        atualizarPagina();
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
    console.log("Enviar para o próximo");
  };

  const atualizarPagina = async () => {
    await apiSetores();
    await apiPedidos();
  };

  useEffect(() => {
    atualizarPagina();
  }, []);

  return (
    <>
      <Container>
        <Menu>
          <li>
            <button onClick={() => atualizarPagina()}>Atualizar</button>
          </li>
          <li>
            <button>Imprimir Todos Aguardando e Enviar para Torno</button>
          </li>
        </Menu>
        <TList>
          {setores.map((item, key) => (
            <ol id={item.id} key={key}>
              <div className="title">{item.attributes.nome}</div>
              {pedidos
                .filter((pedido) => {
                  if (pedido.attributes.producao_setor.data.id === item.id) {
                    return true;
                  }
                  return false;
                })
                .map((item, key) => (
                  <TCard key={key}>
                    <h3>{item.attributes.codigo}</h3>
                    <div className="action">
                      <button onClick={() => handleOpenDetails(item.id)}>
                        <AiOutlineInfoCircle size={22} />
                      </button>
                      <button onClick={() => enviarProximoSetor(item.id)}>
                        <GrFormNextLink size={22} />
                      </button>
                      <button>
                        <AiOutlinePrinter size={22} />
                      </button>
                    </div>
                  </TCard>
                ))}
            </ol>
          ))}
        </TList>
      </Container>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {/* <Typography id="modal-modal-title" variant="h6" component="h2">
            Text in a modal
          </Typography> */}
          <Detalhe pedido={pedido} />
        </Box>
      </Modal>
    </>
  );
};

export default Producao;
