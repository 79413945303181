import { useState, useEffect } from "react";
import * as qs from "qs";
import { addDays, format } from "date-fns";

import Grid from "@mui/material/Grid";

import Layout from "../../layouts/default";
import api from "../../services/api";

import { Link } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import FormHelperText from "@mui/material/FormHelperText";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import OutlinedInput from "@mui/material/OutlinedInput";

import Logo from "../../components/logo";
import Cotacao from "../../components/cotacao";

import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

const schema = Yup.object({
  preco_metal: Yup.number().required("Adicione o preço do metal."),
  preco_mo: Yup.number().required("Adicione o preço da mão de obra."),
  quantidade: Yup.string().required("Você deve escolhar se é Par ou Unidade"),
  peso: Yup.string().required("Peso é obrigatório"),
});

export default function Orcamento() {
  const [cravacoes, setCravacoes] = useState([]);
  const [valorMdO, setValorMdO] = useState(0);
  const [valorMetal, setValorMetal] = useState(0);
  const [valorCravacao, setValorCravacao] = useState(0);
  const [valorExtra, setValorExtra] = useState(0);
  const [valorFrete, setValorFrete] = useState(0);
  const [valorLucro, setValorLucro] = useState(0);
  const [valorTaxa, setValorTaxa] = useState(0);
  const [valorImposto, setValorImposto] = useState(0);
  const [valorVenda, setValorVenda] = useState(0);
  const [validade, setValidade] = useState("00/00/0000");

  const {
    register,
    handleSubmit,
    control,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const watchPeso = watch("peso");
  const watchTitulo = watch("titulo");
  const watchQtd = watch("quantidade");

  const handleOnSubmit = (data) => {
    console.log(data);
    //Definindo pesos
    const peso_alianca = Number(data.peso);
    const peso_total = peso_alianca * 1.1;

    //Definindo preço materia prima
    const custo_metal = peso_total * Number(data.preco_metal);

    //Definindo preço mão de obra
    //Pedras
    let cravacao_valor = 0;
    let custo_pedras = 0;

    if (data.cravacao) {
      const cravacao = cravacoes.find((item) => item.id === data.cravacao);
      cravacao_valor = cravacao.attributes.preco;
    }

    if (cravacao_valor && data.cravacao_qtd) {
      custo_pedras = Number(data.cravacao_qtd) * cravacao_valor;
      setValorCravacao(custo_pedras.toFixed(2));
    }

    //Soldas
    const soldas =
      data.quantidade_cores > 1 ? (data.quantidade_cores - 1) * 80 : 0;
    //Mão de Obra
    const mao_de_obra = peso_alianca * Number(data.preco_mo);

    //Preço por aliança
    const custo_total =
      mao_de_obra +
      custo_metal +
      soldas +
      Number(data.extras) +
      Number(data.frete) +
      custo_pedras;

    const lucro = custo_total * (Number(data.lucro) / 100);

    setValorMdO(mao_de_obra.toFixed(2));
    setValorExtra(Number(data.extras).toFixed(2));
    setValorMetal(custo_metal.toFixed(2));
    setValorLucro(lucro.toFixed(2));
    setValorFrete(Number(data.frete).toFixed(2));

    const taxa = Number(data.taxa) / 100;
    const imposto = Number(data.imposto) / 100;

    const custo_e_lucro = custo_total + lucro;

    const valor_taxa = (taxa * custo_e_lucro) / (1 - (taxa + imposto));
    const valor_imposto = (imposto * custo_e_lucro) / (1 - (taxa + imposto));

    // console.log(valor_taxa);

    const valor_venda = custo_e_lucro + valor_taxa + valor_imposto;

    setValorTaxa(valor_taxa.toFixed(2));
    setValorImposto(valor_imposto.toFixed(2));
    setValorVenda(valor_venda.toFixed(2));

    //Configura validade
    const validade = format(addDays(new Date(), 3), "dd/MM/yyyy");
    setValidade(validade);

    //Copiar o valor
    navigator.clipboard.writeText(
      `✨ ${data.titulo}\n👉 ${valor_venda.toLocaleString("pt-br", {
        style: "currency",
        currency: "BRL",
      })} - *10x de ${(valor_venda / 10).toLocaleString("pt-br", {
        style: "currency",
        currency: "BRL",
      })} sem juros*\n😀 ou *${(valor_venda - valor_taxa).toLocaleString(
        "pt-br",
        { style: "currency", currency: "BRL" }
      )}* no PIX\n\n- ${
        data.quantidade > 1 ? "Par" : "Unidade"
      } com média de *${data.peso}g*${
        data.cravacao_qtd && data.cravacao_qtd > 0
          ? `, ${data.cravacao_qtd} Pedras`
          : ""
      } e acabamento Polido\n\nOferta válida até *${validade}*\n\n📅 Prazo de Produção 10 dias Úteis\n🥇 Garantia Eterna no Ouro\n🖊️ Gravação de Nome Grátis\n🎁 Acompanha Estojo e Certificado de Autenticidade`
    );

    // console.log(data.cravacao_qtd);

    // const info = {
    //   quantidade: data.quantidade,
    //   peso: data.peso,
    //   largura: data.largura,
    //   espessura: data.espessura,
    //   alianca1: data.alianca1,
    //   alianca2: data.alianca2,
    // };

    // console.log(user);
  };

  const changePrice = (price) => {
    // alert(price);
    setValue("preco_metal", price.toFixed(2));
  };

  useEffect(() => {
    //Dados Iniciais
    setValue("titulo", "Alianças *Meia-Cana 3mm* em Ouro 18k");
    setValue("imposto", 8);
    setValue("taxa", 12);
    setValue("lucro", 30);
    setValue("frete", 45.0);
    setValue("extras", 0);
    setValue("quantidade_cores", 1);
    setValue("quantidade", 2);
  }, []);

  useEffect(() => {
    // console.log(service);
    const query = qs.stringify(
      {
        filters: {
          servico: {
            id: {
              $eq: 3,
            },
          },
        },
        populate: "*",
      },
      {
        encodeValuesOnly: true, // prettify url
      }
    );

    // setIsLoading(true);
    api
      .get(`/api/servico-tipos?${query}`)
      .then((resp) => {
        // setCampos(resp.data);
        // setIsLoading(false);
        setCravacoes(resp.data.data);
        // console.log(resp.data.data);
      })
      .catch((err) => {
        // dsds
        // setIsLoading(false);
      })
      .then((resp) => {
        // setCampos(resp.data);
        // setIsLoading(false);
      });
  }, []);

  return (
    <Layout>
      <AppBar position="static" color="primary" enableColorOnDark />
      <Grid sx={{ pt: 4, pb: 4 }}>
        <Logo />
      </Grid>
      <Cotacao click={(value) => changePrice(value)} />
      <Box
        component="form"
        onSubmit={handleSubmit(handleOnSubmit)}
        noValidate
        sx={{ mt: 1, maxWidth: 300, width: "100%" }}
      >
        <Typography sx={{ mt: 1, mb: 0, fontWeight: "bold" }}>
          Orçamento
        </Typography>
        <Typography sx={{ mt: 0, mb: 3, fontSize: 14 }}>
          Preencha os dados para ter um orçamento!
        </Typography>
        <FormControl fullWidth sx={{ mb: 3 }} variant="outlined">
          <TextField
            type="number"
            label="Preço do Metal /g"
            fullWidth
            id="preco_metal"
            name="preco_metal"
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">R$</InputAdornment>
              ),
            }}
            error={errors.preco_metal ? true : false}
            {...register("preco_metal")}
          />
        </FormControl>
        <FormControl fullWidth sx={{ mb: 3 }} variant="outlined">
          <TextField
            type="number"
            label="Preço do Mão de Obra /g"
            fullWidth
            id="preco_mo"
            name="preco_mo"
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">R$</InputAdornment>
              ),
            }}
            error={errors.preco_mo ? true : false}
            {...register("preco_mo")}
          />
        </FormControl>
        {/* <FormControl
          fullWidth
          sx={{ mb: 3 }}
          error={errors.quantidade ? true : false}
        >
          <InputLabel id="quantidade_label">Par ou Unidade</InputLabel>
          <Controller
            name="quantidade"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <Select
                {...field}
                labelId="quantidade_label"
                id="quantidade"
                label="Seleciona Par ou Pé"
                {...register("quantidade")}
              >
                <MenuItem value={2}>Par</MenuItem>
                <MenuItem value={1}>Unidade</MenuItem>
              </Select>
            )}
          />

          {errors && errors.quantidade && errors.quantidade.message && (
            <FormHelperText>{errors.quantidade.message}</FormHelperText>
          )}
        </FormControl> */}
        <FormControl
          fullWidth
          sx={{ mb: 3 }}
          error={errors.quantidade_cores ? true : false}
        >
          <InputLabel id="quantidade_cores_label">Quantidade Cores</InputLabel>
          <Controller
            name="quantidade_cores"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <Select
                {...field}
                labelId="quantidade_cores_label"
                id="quantidade_cores"
                label="Seleciona Par ou Pé"
                {...register("quantidade_cores")}
              >
                <MenuItem value={1}>1</MenuItem>
                <MenuItem value={2}>2</MenuItem>
                <MenuItem value={3}>3</MenuItem>
              </Select>
            )}
          />
          <FormHelperText id="outlined-peso-helper-text">
            {errors &&
            errors.quantidade_cores &&
            errors.quantidade_cores.message ? (
              <FormHelperText>{errors.quantidade_cores.message}</FormHelperText>
            ) : (
              <>É adicionado R$ 80,00 por solda.</>
            )}
          </FormHelperText>
        </FormControl>
        <FormControl fullWidth sx={{ mb: 3 }} variant="outlined">
          <TextField
            type="number"
            margin="normal"
            fullWidth
            id="peso"
            label="Peso"
            name="peso"
            InputProps={{
              endAdornment: <InputAdornment position="end">g</InputAdornment>,
              inputMode: "numeric",
            }}
            error={errors.peso ? true : false}
            {...register("peso")}
            // helperText={errors && errors.peso && errors.peso.message}
          />
          <FormHelperText id="outlined-peso-helper-text">
            {errors && errors.peso && errors.peso.message ? (
              errors.peso.message
            ) : (
              <>
                Nesse peso é adicionado 10% de falha
                <br />
                {watchPeso && (
                  <b>{(watchPeso * 1.1).toFixed(2)}g (Peso + Peso Falha)</b>
                )}
              </>
            )}
          </FormHelperText>
        </FormControl>
        <FormControl
          fullWidth
          sx={{ mb: 3 }}
          error={errors.cravacao ? true : false}
        >
          <InputLabel id="cravacao_label">Cravação</InputLabel>
          <Controller
            name="cravacao"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <Select
                {...field}
                labelId="cravacao_label"
                id="cravacao"
                label="Cravação"
                {...register("cravacao")}
              >
                {cravacoes &&
                  cravacoes.map((item, key) => (
                    <MenuItem key={key} value={item.id}>
                      {item.attributes.nome} (R$ {item.attributes.preco})
                    </MenuItem>
                  ))}
              </Select>
            )}
          />

          {errors && errors.cravacao && errors.cravacao.message && (
            <FormHelperText>{errors.cravacao.message}</FormHelperText>
          )}
        </FormControl>
        <FormControl fullWidth sx={{ mb: 3 }} variant="outlined">
          <TextField
            type="number"
            label="Qtd Pedras"
            fullWidth
            id="cravacao_qtd"
            name="cravacao_qtd"
            InputLabelProps={{
              shrink: true,
            }}
            error={errors.cravacao_qtd ? true : false}
            {...register("cravacao_qtd")}
          />
        </FormControl>
        <FormControl fullWidth sx={{ mb: 3 }} variant="outlined">
          <TextField
            type="number"
            label="Custos Extras"
            fullWidth
            id="extras"
            name="extras"
            InputLabelProps={{
              shrink: true,
            }}
            error={errors.extras ? true : false}
            {...register("extras")}
          />
        </FormControl>
        <FormControl fullWidth sx={{ mb: 3 }} variant="outlined">
          <TextField
            type="number"
            label="Imposto"
            fullWidth
            id="imposto"
            name="imposto"
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
              inputMode: "numeric",
            }}
            error={errors.imposto ? true : false}
            {...register("imposto")}
          />
        </FormControl>
        <FormControl fullWidth sx={{ mb: 3 }} variant="outlined">
          <TextField
            type="number"
            label="Taxa Cartão"
            fullWidth
            id="taxa"
            name="taxa"
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
              inputMode: "numeric",
            }}
            error={errors.taxa ? true : false}
            {...register("taxa")}
          />
        </FormControl>
        <FormControl fullWidth sx={{ mb: 3 }} variant="outlined">
          <TextField
            type="number"
            label="Lucro"
            fullWidth
            id="lucro"
            name="lucro"
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
              inputMode: "numeric",
            }}
            error={errors.lucro ? true : false}
            {...register("lucro")}
          />
        </FormControl>
        <FormControl fullWidth sx={{ mb: 3 }} variant="outlined">
          <TextField
            type="number"
            label="Frete"
            fullWidth
            id="frete"
            name="frete"
            InputLabelProps={{
              shrink: true,
            }}
            error={errors.frete ? true : false}
            {...register("frete")}
          />
        </FormControl>

        <FormControl fullWidth sx={{ mb: 3 }} variant="outlined">
          <TextField
            label="Título"
            fullWidth
            id="titulo"
            name="titulo"
            InputLabelProps={{
              shrink: true,
            }}
            error={errors.titulo ? true : false}
            {...register("titulo")}
          />
        </FormControl>
        <FormControl fullWidth sx={{ mb: 3 }}>
          <Button variant="contained" size="medium" type="submit">
            Calcular e Copiar!
          </Button>
          <Button component={Link} to="/" autoFocus>
            Voltar
          </Button>
        </FormControl>
      </Box>
      {valorVenda > 0 && (
        <Box>
          <strong>Custos</strong>
          <br />
          <strong>Metal:</strong> R$ {valorMetal ? valorMetal : ""} (
          {watchPeso * 1.1}g) com 10% de falha
          <br />
          <strong>Mão de Obra: </strong> R$ {valorMdO ? valorMdO : ""}
          <br />
          <strong>Extra: </strong> R$ {valorExtra ? valorExtra : ""}
          <br />
          <strong>Cravação:</strong> R$ {valorCravacao ? valorCravacao : ""}
          <br />
          <strong>Lucro:</strong> R$ {valorLucro ? valorLucro : ""}
          <br />
          <strong>Taxa:</strong> R$ {valorTaxa ? valorTaxa : ""}
          <br />
          <strong>Imposto:</strong> R$ {valorImposto ? valorImposto : ""}
          <br />
          <strong>Preço Final:</strong> R$ {valorVenda ? valorVenda : ""}
        </Box>
      )}
      {/* <Box mt={3}>
        ✨ {watchTitulo}
        <br />
        👉 R$ {valorVenda} - *10x de R$ {(valorVenda / 10).toFixed(2)} sem
        juros*
        <br />
        😀 ou *R$ {(valorVenda - valorTaxa).toFixed(2)}* no PIX
        <br />
        <br />- {watchQtd > 1 ? "Par" : "Unidade"} com média de *{watchPeso}g* e
        acabamento Polido
        <br />
        <br />
        Oferta válida até *{validade}*
        <br />
        <br />
        📅 Prazo de Produção 10 dias Úteis
        <br />
        🥇 Garantia Eterna no Ouro
        <br />
        🖊️ Gravação de Nome Grátis
        <br />
        🎁 Acompanha Estojo e Certificado de Autenticidade
      </Box>
      <Button
        onClick={() => {
          navigator.clipboard.writeText("Copiado");
        }}
      >
        Copiar
      </Button> */}
    </Layout>
  );
}
