import styled from "styled-components";

export const Menu = styled.div`
  display: flex;
  list-style: none;
`;
export const Container = styled.div``;
export const TList = styled.div`
  display: flex;
  padding: 16px;
  flex-direction: row;
  align-items: start;
  justify-content: space-between;

  div.title {
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 16px;
    text-align: center;
  }

  ol {
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 16px;
    background-color: #00000014;
    border-radius: 10px;
    list-style: none;
  }
`;

export const TCard = styled.li`
  display: flex;
  flex-direction: column;
  background-color: #4bc456;
  border-radius: 22px;
  padding: 6px 16px;
  min-width: 100px;
  margin: 6px 0 6px 0;

  &.atrasado {
    background-color: #4bc456;
  }

  h3 {
    margin: 0 0 10px 0;
    padding: 0;
  }

  .action {
    background-color: #ffffff;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;

    button {
      background-color: transparent;
      border: 0;
      cursor: pointer;
    }
  }
`;
