import React, { useEffect, useState } from "react";
import * as qs from "qs";

import Typography from "@mui/material/Typography";

import Button from "@mui/material/Button";

import { toast } from "react-toastify";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import CircularProgress from "@mui/material/CircularProgress";

import AppBar from "@mui/material/AppBar";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Toolbar from "@mui/material/Toolbar";
import { Link } from "react-router-dom";

import Detalhe from "../../../components/pedido/detalhe";

import api from "../../../services/api";

import Layout from "../../../layouts/default";

import { useParams, useNavigate } from "react-router-dom";

export default function InteractiveList() {
  const [precoOuro, setPrecoOuro] = useState(0);
  const [precoPrata, setPrecoPrata] = useState(0);
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [pedido, setPedido] = useState({});
  let params = useParams();
  const navigate = useNavigate();

  const getPedido = async (id) => {
    setIsLoading(true);
    const query = qs.stringify(
      {
        populate: [
          "imagens",
          "perfil",
          "status_pedido",
          "cliente",
          "materia_prima",
          "materia_prima_tipo",
          "acabamento_externo",
          "acabamento_ferramentas",
          "servicos",
          "servicos.servico",
          "servicos.servico_tipo",
          "entrega",
          "endereco",
        ],
        fields: [
          "id",
          "codigo",
          "largura",
          "peso",
          "alianca_1",
          "alianca_2",
          "quantidade",
          "espessura",
          "observacao",
          "entrega_chave",
          "preco_material",
          "preco_servico",
          "peso_falha",
          "data_entrega",
        ],
      },
      {
        encodeValuesOnly: true,
      }
    );

    await api
      .get(`/api/pedidos/${id}?${query}`)
      .then(function (response) {
        // handle success

        // console.log(response.data);
        setPedido(response.data);
      })
      .catch(function (error) {
        // handle error
        // console.log(error);
        // setLoadingCep(false);
      })
      .then(function () {
        // always executed
        setOpen(false);
        setIsLoading(false);
      });
  };

  const handleDelete = async () => {
    setIsLoading(true);

    await api
      .delete(`/api/pedidos/${params.id}`)
      .then(function (response) {
        // handle success
        navigate("/pedido/todos");
      })
      .catch(function (error) {
        // handle error
        toast.error(error.response.data.error.message);
      })
      .then(function () {
        // always executed
        setOpen(false);
        setIsLoading(false);
      });
  };

  const apiPrecos = async () => {
    setIsLoading(true);
    await api
      .get("/api/metal-precos/ultimo")
      .then((resp) => {
        // console.log(resp.data);
        if (resp.data.ouro) setPrecoOuro(resp.data.ouro);
        if (resp.data.prata) setPrecoPrata(resp.data.prata);
        // setIsLoading(false);
        setIsLoading(false);
      })
      .catch((err) => {
        // dsds
        setIsLoading(false);
      });
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    const chama = () => {
      getPedido(params.id);
      apiPrecos();
    };
    chama();
  }, [params]);

  return (
    <Layout>
      <AppBar position="static" color="primary" enableColorOnDark>
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            component={Link}
            to="/pedido/todos"
          >
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            Pedido <b>{pedido.codigo || `ID ${pedido.id}`}</b>
          </Typography>
        </Toolbar>
      </AppBar>
      <Dialog open={isLoading} sx={{ p: 3 }}>
        <DialogContent>
          <CircularProgress />
        </DialogContent>
      </Dialog>
      <Detalhe pedido={pedido} />

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Quer cancelar esse pedido?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Você tem certeza que deseja cancelar esse pedido? Todas as
            informações serão apagadas.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Não</Button>
          <Button onClick={handleDelete} autoFocus>
            Sim, Tenho certeza!
          </Button>
        </DialogActions>
      </Dialog>
      {pedido && pedido.status_pedido && pedido.status_pedido.id === 1 && (
        <Button variant="contained" color="error" onClick={() => setOpen(true)}>
          CANCELAR PEDIDO
        </Button>
      )}
    </Layout>
  );
}
